import _ from 'lodash';
window._ = _;

// moment.js
import moment from 'moment/moment';
import 'moment/locale/de';
moment.locale('de');
window.moment = moment;

// jQuery
import $ from 'jquery';
window.jQuery = window.$ = $

// Livewire
import {livewire_hot_reload} from 'virtual:livewire-hot-reload';
livewire_hot_reload();

// AlpineJS
import Alpine from 'alpinejs';

// Tooltip
import Tooltip from "@ryangjchandler/alpine-tooltip";
Alpine.plugin(Tooltip);

// Flatpickr
import '../vendor/flatpickr-4.6.13/flatpickr.min';
import '../vendor/flatpickr-4.6.13/plugins/rangePlugin';
import '../vendor/flatpickr-4.6.13/l10n/de.js';

// Powergrid
import './../../vendor/power-components/livewire-powergrid/dist/powergrid';
import "./../../vendor/power-components/livewire-powergrid/dist/powergrid.css";

// Fullcalendar
import { Calendar } from '@fullcalendar/core';
window.Calendar = Calendar;

import dayGridPlugin from '@fullcalendar/daygrid';
window.dayGridPlugin = dayGridPlugin;

import timeGridPlugin from '@fullcalendar/timegrid';
window.timeGridPlugin = timeGridPlugin;

import listPlugin from '@fullcalendar/list';
window.listPlugin = listPlugin;

import interactionPlugin from '@fullcalendar/interaction';
window.interactionPlugin = interactionPlugin;

// SweetAlerts2
import Swal from 'sweetalert2';
window.Swal = Swal;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });

window.Alpine = Alpine;
window.Alpine.start();
